.website-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.header {
  .container {
    @media (max-width: 992px) {
      padding: unset;
    }
  }
  button {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--Primary-Blue---00A0DD, #00a0dd);
    width: 166px;
    border-radius: 4px;
    height: 44px;
    padding: 10px 16px;
    color: var(--White---FFFFFF, #fff);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .header-part {
    height: 84px;
    border-bottom: 1px solid var(--Border-Dark---D0D5DD, #d0d5dd);
    .header-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      position: relative;
      padding: 0 20px;
      img {
        cursor: pointer;
      }
      .account-section {
        display: flex;
        align-items: center;
        position: relative;
        ul {
          display: flex;
          align-items: center;
          .active {
            color: #00a0dd;
          }
          li {
            cursor: pointer;
            list-style: none;
            color: var(--Text-Black---333333, #333);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            margin-right: 32px;
          }
        }
        p {
          margin-bottom: unset;
          color: var(--Text-Black---333333, #333);
          font-size: 16px;
          margin-top: unset;
          font-style: normal;
          margin-bottom: unset;
          font-weight: 400;
          line-height: 24px;
          @media (max-width: 764px) {
            font-size: 11px;
            padding-top: 8px;
          }
          span {
            color: var(--Primary-Blue---00A0DD, #00a0dd);
            font-size: 16px;
            margin-left: 5px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            cursor: pointer;
            @media (max-width: 764px) {
              font-size: 11px;
            }
          }
        }
      }
      @media (max-width: 992px) {
        margin-top: 6px;
      }
    }
    @media (max-width: 992px) {
      height: 52px;
    }
  }
  .mobile-header {
    background: #fff;
    margin-top: 10px;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    // height: 198px;
    transition: 0.3s all ease;
    padding: 0 20px;
    ul {
      padding-left: unset;
      align-items: center;
      .active {
        color: #00a0dd;
      }
      .last-part {
        padding-bottom: 19px !important;
      }
      li {
        margin-top: 19px;
        cursor: pointer;
        list-style: none;
        color: var(--Text-Black---333333, #333);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        margin-right: 32px;
      }
      button {
        margin-top: 19px;
        margin-bottom: 10px;
      }
      .info-button {
        padding-bottom: 19px;
      }
      .info-part {
        display: flex;
        margin-top: 19px;
        h6 {
          color: var(--Text-Black---333333, #333);
          font-size: 16px;
          line-height: normal;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          margin-top: 3px;
          margin-left: 8px;
        }
      }
    }
  }
}
.footer {
  padding: 48px 0;
  background: #002661;
  bottom: 0;
  width: 100%;
  p {
    color: #fff;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .footer-section {
    text-align: center;
    margin: auto;
    .footer-content {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
    }
    a {
      &:hover {
        border-bottom: unset !important;
      }
    }
  }
  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      height: 32px;
    }
    .footer-items {
      display: flex;
      align-items: center;
      a {
        color: #fff;
        outline: none;
        font-size: 16px;
        border-top: unset;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 0;
        text-decoration: none;
        cursor: pointer;
        &:not(:first-child) {
          margin-left: 32px;
        }
        &:hover {
          color: #fff;
          border-bottom: 1px solid #fff;
          transition: 0.3s ease all;
        }
      }
    }
  }
}
.ant-popover-inner {
  padding: unset !important;
}
.ant-popover {
  width: 261px !important;
  background-color: #fff !important;
  .popup-box {
    // border-radius: 4px;
    // background: var(--White---FFFFFF, #fff);
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // border-radius: 8px;
    .info-box {
      display: flex;
      align-items: center;
      border-top: 1.35px solid #eaecf0;
      cursor: pointer;
      padding-left: 15px;
      &:nth-child(1) {
        height: 56px;
      }
      &:nth-child(2) {
        height: 40px;
      }
      &:nth-child(3) {
        height: 40px;
      }
      &:first-child {
        border-top: unset !important;
      }
      .name-section {
        margin-left: 10px;
        h6 {
          margin-bottom: -10px;
          margin-top: unset;
          color: var(--Text-Black---333333, #333);
          font-size: 14.9px;
          font-style: normal;
          font-weight: 700;
          line-height: 27px; /* 142.857% */
        }
        p {
          margin-bottom: unset;
          margin-top: unset;
          color: var(--Grey-Text---475467, #475467);
          font-size: 14.9px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px; /* 142.857% */
          width: 190px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
