.contact-us-section {
  margin-top: 64px;
  .fields-section {
    margin-bottom: 100px;
    @media (max-width: 992px) {
      padding-bottom: 20px;
    }
    button {
      width: 260px;
    }
  }
  .textarea-box {
    display: flex;
    justify-content: center;
    width: 100%;
    .textarea-section {
      display: flex;
      flex-direction: column;
      textarea {
        height: 137px;
        width: 820px;
        margin-bottom: 16px;
      }
    }
    @media (max-width: 992px) {
      display: block;
      width: 100%;
    }
  }
  .fields {
    .colum {
      @media (max-width: 992px) {
        margin-left: unset;
        margin-right: unset;
      }
      .input-box {
        @media (max-width: 992px) {
          width: 100%;
        }
      }
    }
    @media (max-width: 992px) {
      display: block;
    }
  }
  @media (max-width: 992px) {
    margin-top: 24px;
  }
}
.error-section {
  text-align: center;
}
