.profile-section {
  margin-top: 64px;
  @media (max-width: 992px) {
    padding-bottom: 20px;
    margin-top: 30px;
  }
}

.fields-section {
  display: block;
  width: 100%;
  .fields {
    display: block;
    width: 100%;
    .colum {
      display: flex;
      flex-direction: unset;
      justify-content: center;
      .child-box {
        display: inline-grid;
        margin-right: 20px;
        margin-left: 20px;
        label {
          color: var(--Text-Black---333333, #333);
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 4px;
          margin-top: unset !important;
        }
        .ant-select {
          width: 390px;
          @media (max-width: 992px) {
            width: 100%;
          }
        }
        .ant-picker {
          height: 44px;
          margin-bottom: 17px;
          width: 390px;
          @media (max-width: 992px) {
            width: 100%;
          }
        }
        .input-box {
          padding: 10px 14px;
          width: 390px;
          border-radius: 4px;
          margin-bottom: 16px;
          border: 1px solid var(--gray-300, #d0d5dd);
          background: var(--base-white, #fff);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          @media (max-width: 992px) {
            width: 100%;
          }
        }
        @media (max-width: 992px) {
          display: block;
          margin-right: unset;
          margin-left: unset;
        }
      }
      @media (max-width: 992px) {
        display: block;
      }
    }
    @media (max-width: 992px) {
      display: block;
    }
  }
  .button-box {
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      border-radius: 4px;
      border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
      background: var(--Primary-Blue---00A0DD, #00a0dd);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      width: 188px;
      height: 44px;
      color: var(--base-white, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      margin-top: 40px;
    }
  }
}
.title-box {
  h1 {
    color: var(--Text-Black---333333, #333);
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px; /* 126.667% */
    span {
      color: var(--Primary-Blue---00A0DD, #00a0dd);
    }
    @media (max-width: 992px) {
      font-size: 22px;
    }
  }
  p {
    color: var(--gray-600, #475467);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    margin-top: 16px;
    margin-bottom: 40px;
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
}
.profile-status-part {
  padding-bottom: 300px;
  .profile-info-section {
    margin-top: 64px;
    .pending-content {
      border-bottom: unset;
    }
    h1 {
      color: #00a0dd;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px; /* 126.667% */
    }
    .info {
      background: var(--Light-Blue-BG---F2FAFD, #f2fafd);
      width: 100%;
      margin-top: 24px;
      padding: 24px;
      h1 {
        color: var(--Text-Black---333333, #333);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 150% */
      }
      .info-child {
        div {
          display: flex;
          width: 100%;
          p {
            opacity: 0.54;
            color: var(--Text-Black---333333, #333);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 111.111% */
            width: 100px;
          }
          span {
            color: var(--Text-Black---333333, #333);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 111.111% */
          }
        }
      }
    }
    .result-table {
      // border-top: 0;
      // border-bottom: 0;
      border-left: 0;
      border-right: 0;
      @media (max-width: 992px) {
        border-bottom: 1px solid var(--gray-200, #eaecf0) !important;
      }
    }
  }
  .previous-result {
    .desable {
      display: none;
      transition: 0.3s all ease;
    }
    .active {
      transition: 0.3s all ease;
      display: block;
      margin-bottom: 32px !important;
    }
    .asked-box {
      padding-bottom: unset;
      margin-top: 6px;
      .available {
        display: unset;
        .available-info {
          height: 68px;
          .title-section {
            padding-left: 16px;
            padding-right: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
    .under-box {
      background-color: #fff;
      width: 100%;
      margin-top: 14px;
      .result-section {
        padding-bottom: unset;
        margin-top: -15px;
        .result-table {
          // border-top: unset;
          padding: 16px 24px;
          .button-box {
            .button-box-part {
              width: 190px;
              height: 44px;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              background: #f04438;
              border-color: #f04438;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              p {
                color: #fff;
                display: block;
                margin-bottom: unset;
                font-size: unset;
                margin-top: unset;
              }
              @media (max-width: 992px) {
                width: 156px;
                margin-right: -20px;
              }
            }
          }
        }
        @media (max-width: 992px) {
          border-bottom: 1px solid #fff;
        }
      }
    }
    .previous-result-search {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .next-search {
        h2 {
          color: rgba(51, 51, 51, 0.54);
          text-align: right;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 150% */
          margin-bottom: unset;
        }
        h3 {
          color: var(--Primary-Blue---00A0DD, #00a0dd);
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
        }
      }
    }
  }
}
.profile-pending-search {
  .button-box {
    button {
      border-radius: 4px !important;
      border: 1px solid var(--gray-300, #d0d5dd);
      background: rgba(0, 160, 221, 0.38);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--Blue, #002661);
    }
  }
}
.ant-select {
  background: #fff;
  margin-bottom: 16px;
  outline: 0 !important;
  border-radius: 4px;
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 44px;
  &:hover {
    background-color: unset !important;
  }
}
