.profile-navbar {
  border-radius: 6px;
  background: var(--Primary-Blue---00A0DD, #00a0dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  width: 280px;
  height: 644px;
  padding: 24px;
  margin-right: 32px;
  h1 {
    color: var(--White---FFFFFF, #fff);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
  }
  .menu {
    .menu-item-active {
      border-radius: 6px;
      background: #0289bc;
    }
    .menu-item {
      padding: 12px;
      width: 232px;
      height: 40px;
      display: flex;
      cursor: pointer;
      align-items: center;
      img {
        margin-right: 12px;
      }
      p {
        color: var(--White---FFFFFF, #fff);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: unset;
      }
      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }
  @media (max-width: 992px) {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
  }
}
