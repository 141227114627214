.subscibe-section {
  text-align: center;
  margin-top: 96px;
  margin-bottom: 80px;
  h1 {
    color: var(--Text-Black---333333, #333);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; /* 125% */
    margin-top: 36px;
    @media (max-width: 992px) {
      font-size: 22px;
    }
  }
  p {
    color: var(--Grey-Text---475467, #475467);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    margin-top: 24px;
    @media (max-width: 992px) {
      font-size: 16px;
      margin-top: unset;
    }
  }
  .payment-box {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    background-color: unset;
    .item-active {
      border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
    }
    .item {
      margin-bottom: 24px;
    }
    .item {
      cursor: pointer;
      width: 100%;
      padding: 24px 24px 24px 24px;
      background: #fff;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin-right: 32px;
      border-radius: 5px;
      @media (max-width: 992px) {
        padding: 0px 15px 0px 12px;
        padding-bottom: 5px;
      }
    }
    h1 {
      color: var(--Text-Black---333333, #333);
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px; /* 125% */
      letter-spacing: -0.96px;
      margin-top: unset;
      margin-bottom: unset;
      text-align: left;
      @media (max-width: 992px) {
        font-size: 22px;
        margin-top: unset;
      }
    }
    p {
      color: var(--Grey-Text---475467, #475467);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px; /* 161.111% */
      text-align: justify;
      margin-top: unset;
      @media (max-width: 992px) {
        font-size: 18px;
        margin-top: unset;
        line-height: unset;
      }
    }
    @media (max-width: 992px) {
      display: block;
      padding: unset;
    }
  }
  button {
    border-radius: 8px;
    border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
    background: var(--Primary-Blue---00A0DD, #00a0dd);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 390px;
    color: #fff;
    margin-top: 15px;
    @media (max-width: 992px) {
      width: 185px;
    }
  }
  @media (max-width: 992px) {
    margin-top: unset;
  }
}
.asked-box {
  background: var(--Light-Blue-BG---F2FAFD, #f2fafd);
  width: 100%;
  margin-top: 96px;
  padding-bottom: 218px;
  h1 {
    color: var(--Text-Black---333333, #333);
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    padding-top: 96px;
    @media (max-width: 992px) {
      font-size: 21px;
    }
  }
  p {
    color: var(--Grey-Text---475467, #475467);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
  .global-box {
    .available {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .available-info {
        padding-top: 23px;
        .desable {
          visibility: hidden;
          transition: 0.3s all ease;
        }
        .active {
          transition: 0.3s all ease;
          margin-bottom: 32px !important;
        }
        p {
          color: var(--Text-Black---333333, #333);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
          margin-bottom: unset !important;
          text-align: left;
          &:nth-child(2) {
            color: var(--Grey-Text---475467, #475467);
            /* Text md/Regular */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            padding-top: unset;
          }
        }
      }
      img {
        cursor: pointer;
      }
    }
    .hr-box {
      border-top: 1px solid var(--Border-Dark---D0D5DD, #d0d5dd);
      width: 100%;
      margin-top: 10px;
      margin-bottom: 0px;
      &:nth-child(10) {
        display: none !important;
      }
    }
  }
}
.payment-section {
  text-align: center;
  margin-top: 60px;
  h1 {
    color: var(--Text-Black---333333, #333);
    text-align: center;
    /* H3 */
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px; /* 126.667% */
  }
}
.svc-box {
  display: flex;
  width: 100%;
  margin-left: -5px;
  margin-top: 30px;
  div {
    @media (max-width: 992px) {
      &:first-child {
        width: 100%;
      }
    }
  }
  .cvv-box {
    margin-left: 30px;
    margin-right: -10px;
    @media (max-width: 992px) {
      margin-left: 47px;
      width: 100%;
    }
  }
  .StripeElement {
    width: 266px;
    height: 44px;
    border-radius: 4px;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 14px 14px 5px 9px;
    @media (max-width: 992px) {
      width: 100%;
      height: 44px;
    }
  }
}
.checkout-section {
  .checkout-box {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    .stripe-box {
      label {
        color: var(--Text-Black---333333, #333);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      button {
        border-radius: 8px;
        border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
        background: var(--Primary-Blue---00A0DD, #00a0dd);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        display: flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        height: 44px;
        width: 564px;
        color: #fff;
        margin-top: 110px;
        @media (max-width: 992px) {
          width: 100%;
        }
      }
    }
    @media (max-width: 992px) {
      display: block;
    }
  }
  p {
    color: var(--Grey-Text---475467, #475467);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    text-align: center;
    margin-top: 12px;
    @media (max-width: 992px) {
      width: 345px;
      margin: auto;
      margin-top: 15px;
    }
  }
  @media (max-width: 992px) {
    margin-bottom: 80px;
  }
}

.card-element-box {
  height: 44px;
  border-radius: 6px;
  border-radius: var(--4, 4px);
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--white-100, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  width: 564px;
  padding-top: 12px;
  padding-left: 5px;
  padding-right: 5px;
  @media (max-width: 992px) {
    width: 100%;
  }
}
