.sign-up-box {
  margin: auto;
  margin-top: 135px;
  display: grid;
  h1 {
    color: #344054;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
    margin-bottom: 48px;
    @media (max-width: 992px) {
      font-size: 24px;
    }
  }
  label {
    color: var(--gray-700, #344054);
    font-size: 14px;
    font-style: normal;
    display: block;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .form-field-item {
    position: relative;
  }
  input {
    border-radius: var(--4, 4px);
    border: 1px solid var(--gray-300, #d0d5dd);
    border-color: #d0d5dd !important;
    box-shadow: none !important;
    background: var(--white-100, #fff);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 14px;
    align-items: center;
    width: 360px;
    height: 44px;
    margin-bottom: 20px;
    @media (max-width: 992px) {
      width: 343px;
    }
    &:nth-child(7) {
      margin-bottom: 5px !important;
    }
  }
  button {
    border-radius: 4px;
    border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
    background: var(--Primary-Blue---00A0DD, #00a0dd);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 360px;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    height: 44px;
    margin-bottom: 60px;
    ::placeholder {
      font-size: 16px;
      color: var(--gray-500, #667085);
    }
    @media (max-width: 992px) {
      width: 343px;
    }
  }
  button:hover {
    color: #fff !important;
  }
  @media (max-width: 992px) {
    width: 343px;
    margin-top: 60px;
  }
}
.sign-up-section {
  display: flex;
  width: 100%;
}
.error-message {
  color: red;
  margin-bottom: 9px;
  margin-top: -16px;
}
.message-desable {
  visibility: hidden;
}

.sign-in-text {
  text-align: right;
  color: var(--Primary-Blue---00A0DD, #00a0dd);
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  margin-bottom: unset;
}
.character-box {
  color: var(--gray-600, #475467);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: unset;
}
.check-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .keep-box {
    display: flex;
    align-items: center;
    .ant-checkbox-wrapper {
      height: 16px;
    }
    p {
      color: #475467;
      font-size: 14px;
      font-style: normal;
      margin-left: 6px;
      margin-top: unset !important;
      margin-bottom: unset;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}
.check-section {
  margin: auto;
  text-align: center;
  .phone-box {
    .active {
      border: 2px solid var(--primary-600, #00a0dd) !important;
    }
    input {
      border-radius: 8px;
      background: var(--base-white, #fff);
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      width: 96px;
      height: 96px;
      color: var(--Primary-Blue---00A0DD, #00a0dd);
      text-align: center;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px; /* 120% */
      letter-spacing: -1.2px;
      margin-right: 12px;
      &:focus-visible {
        border-color: #00a0dd !important;
        outline: none !important;
      }
      @media (max-width: 992px) {
        width: 44px;
        height: 47px;
        font-size: 12px;
        line-height: unset;
      }
    }
  }
  .sent {
    color: var(--gray-600, #475467);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .email {
    span {
      color: var(--Blue, #002661);
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 24px;
    }
  }
  .resend {
    color: var(--gray-600, #475467);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    span {
      color: #00a0dd;
      margin-left: 10px;
    }
  }
  .back-box {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      margin-left: 10px;
      margin-bottom: unset;
      color: #475467;
    }
  }
}

.password_popover_container {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(40px);
  width: 400px;
  border: 1px solid #abc0d5;
  background-color: #fff;
  z-index: 1000;
  border-radius: 6px;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.password_popover_container .password_validation_item {
  display: flex;
  align-items: center;
  margin: 10px 20px;
  @media (max-width: 992px) {
    margin: 10px 10px;
  }
}

.password_popover_container .password_validation_item.active p {
  color: #00a0dd;
}
.password_validation_item svg {
  position: unset !important;
}
.password_popover_container .password_validation_item.active path {
  color: #00a0dd;
}
.password_popover_container .password_validation_item path {
  color: #abc0d5;
}
.password_popover_container .password_validation_item p {
  color: #abc0d5;
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 0;
  white-space: nowrap;
  margin-bottom: 0;
  margin-left: 10px;
  @media (max-width: 992px) {
    font-size: 12px;
    margin-left: 5px;
  }
}
.ant-checkbox-input {
  display: none;
}

.popover_box {
  .password_popover_container {
    transform: translateY(85px);
    left: unset;
    @media (max-width: 992px) {
      transform: translateY(85px);
    }
  }
}
