.privacy-police {
  margin-top: 64px;
  padding-bottom: 20px;
  p {
    color: var(--gray-600, #475467);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
  }
  .top-section {
    h1 {
      color: var(--Text-Black---333333, #333);
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
    }
    p {
      text-align: center;
    }
  }
  .point {
    margin-top: 46px;
    p {
      text-align: left;
    }
  }
}
