.profile-section {
  margin-bottom: 170px;
  .profile-section-child {
    display: flex;
    .profile {
      width: 100%;
      .profile-top-section {
        .top-box-content-plan {
          @media (max-width: 992px) {
            display: unset !important;
          }
          button {
            margin-bottom: 20px;
          }
        }
        .top-box-content {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .top-child {
            h1 {
              color: var(--Text-Black---333333, #333);
              font-size: 22px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px; /* 127.273% */
              @media (max-width: 992px) {
                font-size: 18px;
              }
            }
            p {
              color: var(--gray-600, #475467);
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 133.333% */
              @media (max-width: 992px) {
                font-size: 16px;
              }
            }
          }
          .custom-ui {
            @media (max-width: 992px) {
              width: 28px !important;
              height: 28px !important;
              min-width: unset;
              padding: 5px !important;
            }
          }
          .switch {
            border-radius: 4px;
            border: 1px solid var(--Border-Dark---D0D5DD, #d0d5dd);
            background: var(--White---FFFFFF, #fff);
            color: var(--Grey-Text---475467, #475467);
            padding: 16px;
            width: 164px;
          }
          button {
            display: flex;
            height: 44px;
            min-width: 110px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
            background: var(--Primary-Blue---00A0DD, #00a0dd);
            color: #fff;
          }
        }
        .hr-box {
          border: 1px solid #d0d5dd;
          height: 1px;
          align-self: stretch;
        }
      }
      .profile-info-box {
        margin-top: 24px;
        .colum {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:last-child {
            margin-top: 32px;
          }
          div {
            width: 200px;
            label {
              opacity: 0.54;
              color: var(--Text-Black---333333, #333);
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }
            p {
              color: #333;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 111.111% */
              @media (max-width: 992px) {
                width: 130px;
                font-size: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          @media (max-width: 992px) {
            display: unset;
          }
        }
      }
      .profile-edit {
        .colum-fileds {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 24px;
          div {
            width: 282px;
            label {
              color: var(--Text-Black---333333, #333);
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            .input-box {
              height: 44px;
              border-radius: 4px;
              border: 1px solid var(--gray-300, #d0d5dd);
              margin-bottom: 16px;
              background: var(--base-white, #fff);
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
            .ant-picker {
              height: 44px;
              margin-bottom: 17px;
            }
            @media (max-width: 992px) {
              width: 100%;
            }
          }
          @media (max-width: 992px) {
            flex-direction: column;
          }
        }
        .button-box {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          button {
            width: 188px;
            margin-top: 24px;
            color: #fff;
            height: 44px;
            border-radius: 4px;
            border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
            background: var(--Primary-Blue---00A0DD, #00a0dd);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            @media (max-width: 992px) {
              width: 165px;
              height: 40px;
            }
          }
        }
        .button-box-bottom {
          display: flex;
          justify-content: flex-end;
          .button-box {
            width: unset;
            display: unset;
            &:first-child {
              margin-right: 16px;
              button {
                border: 1px solid var(--Border-Dark---D0D5DD, #d0d5dd);
                background: var(--White---FFFFFF, #fff);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                color: var(--Grey-Text---475467, #475467);
              }
            }
          }
          @media (max-width: 992px) {
            justify-content: center;
          }
        }
      }
    }
    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: auto;
    }
  }
  @media (max-width: 992px) {
    margin-bottom: 30px;
  }
}
