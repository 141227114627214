* {
  margin: 0;
  padding: 0;
}

.ant-modal-footer {
  display: none;
}

.ant-modal {
  width: max-content !important;
}

body {
  font-family: 'Lato', sans-serif;
  position: relative;
  min-height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.big-spinner {
  height: calc(100vh - 128px);
  display: flex;
  margin-bottom: -128px;
  width: 100%;
  justify-content: center;
  align-items: center;
  .MuiCircularProgress-root {
    width: 40px;
  }
  .MuiCircularProgress-svg {
    color: #37a0dd;
  }
}
.ant-btn {
  &:hover {
    border-radius: 4px !important;
    background: var(--Blue, #002661) !important;
    border-color: #002661 !important;
    color: #fff !important;
    transition: 0.3s all ease !important;
  }
}
.grecaptcha-badge {
  display: none !important;
}
