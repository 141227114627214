.information-container {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 41.4%, #f2fafd 84.47%);
  padding: 130px 0;
  .information {
    text-align: center;
    h1 {
      color: var(--Blue, #002661);
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      @media (max-width: 992px) {
        font-size: 25px;
      }
    }
    p {
      color: var(--Blue, #002661);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      margin-top: 19px;
      font-weight: 400;
      line-height: 36px;
      opacity: 0.75;
      @media (max-width: 992px) {
        font-size: 16px;
        width: 345px;
        margin: auto;
      }
    }
    .lock {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 225% */
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 5px;
        width: 16px;
      }
    }
  }
  @media (max-width: 992px) {
    padding: 40px 0;
  }
}
.anonymous-private {
  text-align: center;
  padding-top: 130px;
  padding-bottom: 130px;
  background: rgba(0, 160, 221, 0.05);
  h1 {
    color: var(--Blue, #002661);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    @media (max-width: 992px) {
      font-size: 25px;
    }
  }
  p {
    color: var(--Blue, #002661);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    margin-top: 19px;
    font-weight: 400;
    line-height: 36px;
    opacity: 0.75;
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
  .can-trust {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 300px;
      margin-top: 64px;
      img {
        width: 52px;
        @media (max-width: 992px) {
          width: 28px;
        }
      }
      p {
        color: var(--Blue, #002661);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width: 992px) {
          font-size: 16px;
        }
      }
      @media (max-width: 992px) {
        width: 172px;
      }
    }
    @media (max-width: 992px) {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 992px) {
    padding-top: unset;
    padding-bottom: 30px;
  }
}

.details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 130px;
  margin-bottom: 130px;
  .vector {
    margin-left: 30px;
    margin-right: 30px;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .details-box {
    text-align: center;
    border-radius: 4px;
    background: rgba(0, 160, 221, 0.05);
    width: 252px;
    height: 239px;
    padding: 30px 30px 0;
    h1 {
      color: var(--Blue, #002661);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 200% */
      letter-spacing: 0.1px;
      margin-top: 20px;
      margin-bottom: unset;
      @media (max-width: 992px) {
        font-size: 25px;
      }
    }
    p {
      color: var(--Blue, #002661);
      text-align: center;
      font-size: 16px;
      margin-top: unset;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 156.25% */
      @media (max-width: 992px) {
        font-size: 16px;
      }
    }
    img {
      @media (max-width: 992px) {
        width: 40px;
      }
    }
    @media (max-width: 992px) {
      margin-bottom: 20px;
      height: 185px;
      width: 92%;
      margin-bottom: 20px;
      margin-right: -1px;
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

.statistics-container {
  background: rgba(0, 160, 221, 0.05);
  .statistics-box {
    padding-top: 96px;
    padding-bottom: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      color: #002661;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 20px;
      @media (max-width: 992px) {
        font-size: 25px;
      }
    }
    p {
      color: #002661;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      opacity: 0.75;
      @media (max-width: 992px) {
        font-size: 16px;
      }
    }
    .statistics-content {
      margin-top: 64px;
      display: flex;
      align-items: center;
      .statistic-item {
        width: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h5 {
          color: #002661;
          text-align: center;
          font-size: 60px;
          font-style: normal;
          font-weight: 700;
          line-height: 72px;
          margin-bottom: 12px;
          margin-top: 0;
          @media (max-width: 992px) {
            font-size: 26px;
            margin-bottom: -20px;
          }
        }
        p {
          color: var(--Blue, #002661);
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          opacity: 0.75;
          margin-top: 0;
          margin-bottom: 0;
          @media (max-width: 992px) {
            font-size: 16px;
          }
        }
        @media (max-width: 992px) {
          width: 172px;
        }
      }
      .statistic-line {
        width: 1px;
        background-color: #00a0dd;
        height: 112px;
        margin: 0 16px;
        @media (max-width: 992px) {
          display: none;
        }
      }
      @media (max-width: 992px) {
        flex-wrap: wrap;
        margin-top: unset;
        justify-content: center;
      }
    }
    @media (max-width: 992px) {
      padding-top: 40px;
      padding-bottom: 25px;
    }
  }
}

.search-container {
  margin: 96px 0 0;
  padding-bottom: 70px;
  h1 {
    color: #002661;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 0;
    span {
      font-weight: 900;
    }
    @media (max-width: 992px) {
      width: 345px;
      text-align: center;
      font-size: 24px;
      margin: auto;
    }
  }
  p {
    color: #002661;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    opacity: 0.75;
    margin-top: 20px;
    margin-bottom: 0;
    @media (max-width: 992px) {
      font-size: 16px;
      width: 345px;
      padding-top: 10px;
      margin: auto;
      line-height: unset;
    }
  }
  @media (max-width: 992px) {
    margin: 30px 0 0;
    padding-bottom: 20px;
  }
}

.search-box {
  margin-top: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.04), 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  height: 174px;
  border-radius: 12px;
  width: 100%;
  padding: 50px;
  div {
    display: flex;
    flex-direction: column;
    margin-right: 42px;
    label {
      text-align: justify;
      color: var(--Blue, #002661);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      opacity: 0.75;
      @media (max-width: 992px) {
        margin-top: 18px;
      }
    }
    input {
      background: #fff;
      width: 186.4px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      margin-top: 20px;
      border-color: rgba(166, 166, 166, 0.2);
      color: #002661;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      box-shadow: none !important;
      outline: 0 !important;
      opacity: 0.85;
      &:hover {
        background-color: unset !important;
      }
      &:focus {
        border-color: #f0f0f0;
      }
      &::placeholder {
        opacity: 0.2;
        color: #002661;
      }
      @media (max-width: 1400px) {
        width: 130px;
        font-size: 17px;
      }
      @media (max-width: 992px) {
        width: 100%;
        padding: 4px 2px;
        margin-top: 10px;
      }
    }
    .ant-select {
      background: #fff;
      width: 186.4px;
      height: 53px;
      border-color: rgba(166, 166, 166, 0.2) !important;
      outline: 0 !important;
      box-shadow: none !important;
      margin-bottom: 0;
      &:hover {
        background-color: unset !important;
      }
      .ant-select-selection-item {
        // min-height: 30px;
        width: 100%;
        text-align: left;
        color: #002661;
        font-size: 22px;
        font-weight: 500;
        line-height: normal;
        opacity: 0.85;
        @media (max-width: 992px) {
          font-size: 17px;
          padding-bottom: 8px;
        }
      }
      .ant-select-arrow {
        margin-top: 5px;
      }
      .ant-select-selector {
        margin-top: 24px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-color: rgba(166, 166, 166, 0.2) !important;
        outline: 0 !important;
        box-shadow: none !important;
        @media (max-width: 992px) {
          padding: unset;
          margin-top: 15px;
        }
        &::after {
          content: none !important;
        }
      }
      &:hover {
        background-color: unset !important;
      }
      @media (max-width: 1400px) {
        width: 130px;
        font-size: 17px;
      }
      @media (max-width: 992px) {
        width: 100%;
        padding: unset;
        height: unset;
      }
    }
    @media (max-width: 1400px) {
      margin-right: 29px;
    }
    @media (max-width: 992px) {
      margin-right: unset;
    }
  }
  button {
    width: 186px;
    height: 64px;
    border-radius: 4px;
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    background: #00a0dd;
    border: 0;
    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 5px;
      margin-top: 21px;
      height: 54px;
    }
  }
  @media (max-width: 992px) {
    display: block;
    height: unset;
    padding: 0px 16px 16px 16px;
  }
}
