.input {
  height: 48px;
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid #383e55;
  input {
    font-weight: bold;
    color: #abc0d5;
    background-color: transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s, font-size 600000s 0s;
    }
    &::placeholder {
      color: #abc0d5;
    }
  }
  .ant-input-prefix {
    margin-inline-end: 12px;
  }
}

.button {
  width: 100%;
  color: #fff;
  text-align: center;
  background: #348ccb;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border: unset;
  box-shadow: none;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  &.button-disabled {
    cursor: not-allowed;
  }
}
button {
  .MuiCircularProgress-root {
    width: 22px !important;
    height: unset !important;
  }
  .MuiCircularProgress-svg {
    color: #fff !important;
  }
}
