.search-result-section {
  background: var(--Light-Blue-BG---F2FAFD, #f2fafd);
  box-shadow: unset;
  input {
    background: var(--Light-Blue-BG---F2FAFD, #f2fafd) !important;
  }
  select {
    background: var(--Light-Blue-BG---F2FAFD, #f2fafd) !important;
  }
  .ant-select {
    background: var(--Light-Blue-BG---F2FAFD, #f2fafd) !important;
  }
  .ant-select-selector {
    background: var(--Light-Blue-BG---F2FAFD, #f2fafd) !important;
  }
}
.result-section {
  padding-bottom: 20px;
  margin-top: 32px;
}
.result-custom-section {
  @media (max-width: 992px) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.49) 0%, rgba(255, 255, 255, 0.49) 100%),
      #eaecf0 !important;
  }
  .top-box {
    @media (max-width: 992px) {
      display: none;
    }
  }
  .logo-box {
    @media (max-width: 992px) {
      display: none !important;
    }
  }
  .text-found {
    @media (max-width: 992px) {
      display: none;
    }
  }
  .middle-box {
    @media (max-width: 992px) {
      padding-top: unset !important;
      p {
        width: 315px;
      }
    }
  }
  .all-section {
    @media (max-width: 992px) {
      display: flex !important;
    }
  }
  .info-state {
    @media (max-width: 992px) {
      margin-left: 28px !important;
    }
  }
  .button-box {
    @media (max-width: 992px) {
      margin-left: 15px;
      display: flex;
      width: 92%;
      margin-top: unset !important;
      margin-bottom: 30px;
    }
    button {
      @media (max-width: 992px) {
        width: 100% !important;
      }
    }
  }
  .all-section {
    .extra-match {
      @media (max-width: 992px) {
        position: absolute;
        cursor: pointer;
        right: 10px;
        margin-top: -64px;
      }
    }
  }
  .global-match-found {
    @media (max-width: 992px) {
      margin-left: 26px;
      height: 100px;
    }
  }
  .global-box {
    @media (max-width: 992px) {
      padding-top: 24px !important;
    }
  }
  .check-state {
    @media (max-width: 992px) {
      margin-top: unset !important;
    }
  }
}
.search-result-parent {
  .result-table {
    border: 1px solid #eaecf0;
    .global-box {
      border-bottom: 1px solid #eaecf0;
    }
  }
}
.result-table {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 40px;
  .logo-box {
    padding-top: 24px;
    display: flex;
    padding-left: 24px;
    h6 {
      margin-left: 8px;
      color: var(--Text-Black---333333, #333);
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 87.5% */
    }
    img {
      height: 28px;
    }
  }
  .text-found {
    color: var(--Blue, #002661);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
    margin-top: 16px;
    padding-left: 24px;
    span {
      color: var(--Primary-Blue---00A0DD, #00a0dd);
    }
  }
  .global-box {
    display: flex;
    padding-left: 24px;
    margin-top: 40px;
    justify-content: space-between;
    padding-bottom: 30px;
    .check-state {
      display: flex;
      align-items: center;
      label {
        @media (max-width: 992px) {
          display: none;
        }
      }
      .scales-checkbox {
        margin-top: -10px;
      }
      .info-state {
        margin-left: 10px;
        p {
          width: 230px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @media (max-width: 992px) {
          margin-left: unset;
        }
      }
    }
    .all-section {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 288px);
      .middle-box {
        .name-state {
          color: var(--Blue, #002661);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 100% */
        }
        p {
          color: #b5b5b5;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 111.111% */
          margin-bottom: unset;
          span {
            color: var(--Text-Black---333333, #333);
            opacity: 1;
            margin-left: 5px;
          }
        }
      }
      @media (max-width: 992px) {
        display: unset;
      }
    }

    .button-box {
      display: flex;
      margin-right: 10px;
      .button-box-part {
        width: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        margin-right: 15px;
        border-radius: 4px;
        width: 280px;
        height: 44px;
        border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
        background: var(--Primary-Blue---00A0DD, #00a0dd);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--base-white, #fff);
        p {
          text-align: center;
          margin-bottom: unset;
        }
      }
      button {
        width: 110px;
        height: 44px;
        margin-right: 15px;
        &:nth-child(1) {
          border-radius: 4px;
          width: 280px;
          height: 44px;
          border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
          background: var(--Primary-Blue---00A0DD, #00a0dd);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: var(--base-white, #fff);
        }
      }
    }
    .bottom-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: 25px;
      button {
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--base-white, #fff);
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
    @media (max-width: 992px) {
      display: block;
      padding-left: unset;
    }
  }
}
.top-box {
  display: flex;
  background: var(--gray-50, #f9fafb);
  width: 100%;
  padding-left: 24px;
  p {
    margin-bottom: unset;
    margin-top: 10px;
  }
  .check-section {
    display: flex;
    margin: unset;
    width: 288px;
    height: 44px;
    p {
      margin-top: unset;
      margin-bottom: unset;
      margin-left: 10px;
      margin-top: 10px;
    }
  }
}
.top-box {
  width: unset;
}
.pending-global-box {
  border-right: 1px solid var(--Border-Dark---D0D5DD, #d0d5dd);
  border-bottom: 1px solid var(--Border-Dark---D0D5DD, #d0d5dd);
  border-left: 1px solid var(--Border-Dark---D0D5DD, #d0d5dd);
  padding-top: 10px;
  .top-box-pending {
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    justify-content: space-between;
    .web-result-section {
      display: flex;
    }
    .remove-part {
      margin-right: 44px;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
  .pending-content {
    margin-top: -2px;
    padding-top: 16px;
  }
}
.available-pending {
  .available-info {
    margin-top: 20px !important;
    cursor: pointer;
  }
}

.result-custom-table {
  position: relative;
  .global-box {
    margin-top: unset !important;
    padding-bottom: unset !important;
    .check-state {
      margin-top: 20px;
    }
    .global-match-found {
      background: rgba(40, 199, 113, 0.07) !important;
      display: flex !important;
      align-items: center;
      p {
        color: var(--Text-Black---333333, #333);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 100% */
        @media (max-width: 992px) {
          margin-top: 15px;
        }
      }
    }

    .all-section {
      padding-left: 24px;
      padding-bottom: 20px;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.49) 0%,
          rgba(255, 255, 255, 0.49) 100%
        ),
        #eaecf0;
      .middle-box {
        padding-top: 40px;
      }
      .extra-match {
        border-radius: 0px 0px 0px 10px;
        background: var(--Text-Black---333333, #333);
        width: 105px;
        height: 30px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        @media (max-width: 992px) {
          right: 1px;
          margin-top: -90px;
        }
      }
      .parial-match {
        border-radius: 0px 0px 0px 10px;
        background: rgba(71, 84, 103, 0.68);
      }
      @media (max-width: 992px) {
        width: 315px;
      }
    }
    .button-box {
      padding-left: 24px;
      margin-top: 40px;
    }
  }
  .button-box {
    button {
      border-color: #eaecf0 !important;
      background-color: #ffff !important;
      color: #7e8fae !important;
    }
  }
  // .is-in-progress-box {
  //   padding: unset;
  //   button {
  //     display: flex;
  //     align-items: center;
  //     .is-in-progress {
  //       width: 58px;
  //       margin-right: 25px;
  //       display: flex;
  //       margin-left: -15px;
  //       height: 42px;
  //       background: rgba(0, 160, 221, 0.38);
  //       border-radius: 4px 0 0 4px;
  //     }
  //   }
  // }
  .complete {
    button {
      border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd) !important;
      background: var(--Primary-Blue---00A0DD, #00a0dd) !important;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
      color: #fff !important;
    }
  }
  // .in-progress {
  //   button {
  //     border: 1px solid var(--gray-300, #d0d5dd) !important;
  //     background: var(--Light-Blue-BG---F2FAFD, #f2fafd) !important;
  //     box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  //   }
  // }
  // .needed {
  //   button {
  //     border: 1px solid #f04438 !important;
  //     color: #fff !important;
  //     background: #f04438 !important;
  //     box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  //   }
}
.sticky-section {
  display: flex;
  position: relative;
  .data-section {
    width: 100%;
  }
  .yourself-section {
    @media (max-width: 992px) {
      display: flex;
      justify-content: center;
      width: 100%;
      bottom: 13px;
      position: sticky;
    }
  }
  .yourself-box {
    background-color: #0a2661;
    width: 300px;
    min-width: 300px;
    height: 260px;
    z-index: 1000;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    margin-top: 30px;
    margin: 30px;
    position: sticky;
    top: 48px;
    img {
      position: absolute;
    }
    .img-section {
      display: flex;
      justify-content: space-between;
      .img-box {
        img {
          animation: opacity both infinite;
          animation-duration: 3.5s;
          animation-delay: 0.5s;
          left: 25px;
          top: 49px;
        }
      }
      .img-box-second {
        img {
          animation: opacity both infinite;
          animation-duration: 3.5s;
          animation-delay: 2s;
          right: 16px;
          top: 36px;
        }
      }
    }
    .img-box-third {
      img {
        animation: opacity both infinite;
        animation-duration: 3.5s;
        animation-delay: 3.5;
        left: 118px;
        top: 160px;
        width: 50px;
        height: 50px;
        z-index: -1000;
      }
    }
    .img-box-third-fourth {
      img {
        animation: opacity both infinite;
        animation-duration: 3.5s;
        animation-delay: 5s;
        right: 5px;
        top: 139px;
        width: 49px;
        height: 65px;
        z-index: -1000;
      }
    }
    h1 {
      color: var(--White---FFFFFF, #fff);
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
      padding-top: 53px;
      @media (max-width: 992px) {
        padding-top: 28px;
      }
    }
    p {
      color: var(--White---FFFFFF, #fff);
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      width: 215px;
      margin: auto;
      margin-top: -13px;
      @media (max-width: 992px) {
        width: 315px;
        font-size: 15px;
      }
    }
    button {
      border-radius: 4px;
      border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
      background: var(--Primary-Blue---00A0DD, #00a0dd);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      width: 238px;
      height: 44px;
      color: #fff;
      margin-top: 38px;
    }
    @media (max-width: 992px) {
      top: unset;
      min-width: unset;
      margin: unset;
      top: unset;
      width: 100%;
    }
  }
  @media (max-width: 992px) {
    display: block;
  }
}

.mobile-part {
  p {
    display: none;
  }
  @media (max-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 20px;
    p {
      display: block;
      margin-bottom: unset;
      margin-top: unset;
      font-size: 12px;
      margin-top: 12px;
    }
  }
}
.button-box-match {
  visibility: hidden;
}

@keyframes opacity {
  0% {
    opacity: 1;
    transform: translateY(-50%);
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.2;
    transform: translateY(0%);
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(-50%);
  }
}
