.account {
  background: var(--Light-Blue-BG---F2FAFD, #f2fafd);
  width: 100%;
  padding: 24px;
  margin-top: 32px;
  h1 {
    color: var(--Text-Black---333333, #333);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
  }
  p {
    padding-top: 16px;
    color: var(--Grey-Text---475467, #475467);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
  }
  .hr-box {
    margin-top: 20px;
    border: 1px solid var(--Border-Dark---D0D5DD, #d0d5dd);
  }
  .bottom-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: #f04438;
      font-size: 16px;
      font-style: normal;
      margin-bottom: unset;
      font-weight: 600;
      line-height: 24px; /* 150% */
      cursor: pointer;
    }
    .current-plan-btn {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      button {
        width: 164px;
        padding: 10px 16px;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #f04438;
        background: #f04438;
        color: #fff;
        margin-top: 20px;
        height: 44px;
        margin-bottom: 10px;
        &:hover {
          border: 1px solid #f04438 !important;
          background: var(--White---FFFFFF, #fff) !important;
          color: var(--Danger, #f23051) !important;
        }
      }
    }
  }
  .delete-title {
    margin-top: 83px;
  }
}
.account-canceled {
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: 30px;
    @media (max-width: 992px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 24px;
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
  .button-box {
    display: flex;
    justify-content: center;
    button {
      height: 44px;
      width: 165px;
      margin-top: 20px;
      &:first-child {
        border-radius: 4px;
        border: 1px solid #f04438;
        background: #f04438;
        color: #fff;
        &:hover {
          background: var(--White---FFFFFF, #fff) !important;
          color: var(--Danger, #f23051) !important;
          border: 1px solid #f04438 !important;
        }
      }
      &:last-child {
        border-radius: 4px;
        border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
        background: var(--Primary-Blue---00A0DD, #00a0dd);
        color: var(--White---FFFFFF, #fff);
        margin-left: 15px;
      }
    }
  }
}
.payment-box {
  width: 100%;
  padding: 24px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 32px;
  background: var(--Light-Blue-BG---F2FAFD, #f2fafd);
  h1 {
    text-align: center;
    margin-top: 32px;
    color: var(--Text-Black---333333, #333);
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 60px;
  }
  .payment-section {
    display: flex;
    justify-content: center;
    margin-top: unset;
    width: 100%;
    .handle-size {
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
      .active-part {
        .box {
          border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
        }
      }
      .handle-part {
        margin-left: 12px;
        margin-right: 12px;
        @media (max-width: 1400px) {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }
      @media (max-width: 1400px) {
        display: block;
        margin: auto;
        text-align: center;
        .box {
          margin-bottom: 50px;
        }
      }
    }
    .btn-section {
      width: 392px;
      .disable-button {
        display: none;
      }
      .active-button {
        margin-top: -24px;
        width: 70px;
        border-radius: 4px 4px 0px 0px;
        border-color: #00a0dd;
        background: var(--Primary-Blue---00A0DD, #00a0dd);
        // width: 60px;
        color: var(--White---FFFFFF, #fff);
        font-size: 14px;
        display: flex;
        text-align: center;
        align-content: center;
        justify-content: space-between;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        display: block;
        text-transform: capitalize;
      }
      @media (max-width: 992px) {
        width: 100%;
      }
    }
    .box {
      cursor: pointer;
      border-radius: 0px 4px 4px 4px;
      border: 1px solid var(--Border-Dark---D0D5DD, #d0d5dd);
      background: var(--White---FFFFFF, #fff);
      width: 392px;
      height: 190px;
      padding: 24px;
      text-align: left;
      h6 {
        color: #000;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        span {
          color: var(--Grey-Text---475467, #475467);
        }
        @media (max-width: 992px) {
          font-size: 22px;
        }
      }
      p {
        color: var(--Grey-Text---475467, #475467);
        font-size: 18px;
        margin-top: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px; /* 161.111% */
        @media (max-width: 992px) {
          font-size: 14px;
          line-height: unset;
        }
      }
      @media (max-width: 992px) {
        margin-bottom: 60px;
        width: 100%;
        padding: 10px;
      }
    }
    @media (max-width: 992px) {
      display: block;
      margin: auto;
      text-align: center;
      width: 100%;
    }
  }
  .upgrade-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    button {
      border-radius: 4px;
      border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
      background: var(--Primary-Blue---00A0DD, #00a0dd);
      color: #fff;
      width: 165px;
      height: 44px;
    }
    @media (max-width: 992px) {
      margin-top: -20px;
    }
  }
  @media (max-width: 992px) {
    margin-bottom: 40px;
  }
}
.subsribe-button {
  div {
    margin-top: 10px;
  }
}
.payment-button {
  display: flex;
  justify-content: space-between;
  div {
    width: 151px;
    height: 39px;
    border-radius: 180px;
    background: var(--Primary-Blue---00A0DD, #00a0dd);
    color: var(--White---FFFFFF, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    line-height: normal;
    text-align: center;
    align-items: center;
    justify-content: center;
    @media (max-width: 992px) {
      width: 114px;
      font-size: 14px;
      padding: 10px 10px;
    }
  }
}

.profile-delete-modal {
  .ant-modal-close {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: end;
  }
  .ant-modal-content {
    width: 540px;
    height: 240px;
    background: var(--Light-Blue-BG---F2FAFD, #f2fafd);
    .delete-part {
      padding-top: 50px;
      h1 {
        color: var(--Text-Black---333333, #333);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 100% */
        text-align: center;
        @media (max-width: 992px) {
          font-size: 18px;
        }
      }
      .account {
        margin-top: unset;
        padding: unset;
        button {
          width: 186px;
        }
      }
    }
    @media (max-width: 764px) {
      width: 100%;
    }
  }
}
