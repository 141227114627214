.security-box {
  margin-top: 12px;
  position: relative;
  label {
    color: var(--Text-Black---333333, #333);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
  }
  input {
    height: 44px;
    width: 100%;
    margin-bottom: 15px;
  }
  .button-box {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 34px;
    p {
      color: var(--Primary-Blue---00A0DD, #00a0dd);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;
    }
    button {
      border-radius: 4px;
      border: 1px solid var(--Primary-Blue---00A0DD, #00a0dd);
      background: var(--Primary-Blue---00A0DD, #00a0dd);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: #fff;
      width: 188px;
      height: 44px;
      @media (max-width: 992px) {
        width: 150px;
      }
    }
  }
  .password_popover_container {
    transform: translateY(85px);
    @media (max-width: 992px) {
      transform: translateY(85px);
    }
  }
  @media (max-width: 992px) {
    margin-bottom: 100px;
  }
}
.check-email-box {
  border-radius: 8px;
  background: var(--Text-Black---333333, #333);
  height: 52px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  img {
    margin-right: 6px;
    margin-left: 12px;
    width: 24px;
    height: 24px;
  }
  p {
    color: var(--base-white, #fff);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 163.636% */
    margin-bottom: unset;
    @media (max-width: 992px) {
      font-size: 10px;
    }
    @media (max-width: 400px) {
      font-size: 8px;
    }
  }
}
